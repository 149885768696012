.cUpcomingEvents > ul {
	list-style-type: none;
	padding: 0;
}

.cUpcomingEvents > ul > li > ol {
	padding: 0;
}

.cUpcomingEvents .cUpcomingEvents-event > span {
	vertical-align: bottom;
}
