.c-alert {
	padding: 24px;
	margin-bottom: 8px;
}

.c-alert.success {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
}
.c-alert.warning {
	color: #8a6d3b;
	background-color: #f7efc6;
	border-color: #faebcc;
}

.c-alert.danger {
	color: #c34343;
	background-color: #f2dede;
	border-color: #de9090;
}
.c-alert.danger span {
	color: #c34343;
}

.c-alert.info {
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bce8f1;
}

.c-alert > button {
	float: right;
	position: relative;
	top: -14px;
	right: -16px;
}