.c-preloader {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: default;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9000;
}

.c-preloader .animation-preloader {
	position: absolute;
	z-index: 1000;
}

.c-preloader .animation-preloader .spinner {
	-webkit-animation: spinner 1s infinite linear;
	animation: spinner 1s infinite linear;
	border-radius: 50%;
	border: 3px solid rgba(0, 0, 0, 0.2);
	border-top-color: #000000;
	height: 9em;
	margin: 0 auto 3.5em auto;
	width: 9em;
}

.c-preloader .animation-preloader .txt-loading {
	font: bold 5em "Poppins", sans-serif;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.c-preloader .animation-preloader .txt-loading .letters-static {
	color: rgba(0, 0, 0, 0.2);
	position: relative;
}

.c-preloader .animation-preloader .txt-loading .letters-animated {
	-webkit-animation: letters-static 4s infinite;
	animation: letters-static 4s infinite;
	color: #000000;
	/*content: attr(data-text-preloader);*/
	left: 0;
	opacity: 0;
	font-family: "Poppins", sans-serif;
	position: absolute;
	top: -3px;
	-webkit-transform: rotateY(-90deg);
	transform: rotateY(-90deg);
}

.c-preloader.dark .animation-preloader .spinner {
	border-color: rgba(255, 255, 255, 0.2);
	border-top-color: #fff;
}

.c-preloader.dark .animation-preloader .txt-loading .letters-static {
	color: rgba(255, 255, 255, 0.2);
}

.c-preloader.dark .animation-preloader .txt-loading .letters-animated {
	color: #fff;
}

.c-preloader p {
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 8px;
	color: #3b3b3b;
}

.c-preloader .loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 0;
	z-index: 1;
	pointer-events: none;
}

.c-preloader .loader .row {
	height: 100%;
}

.c-preloader .loader .loader-section {
	padding: 0px;
}

.c-preloader .loader .loader-section .bg {
	background-color: #ffffff;
	height: 100%;
	left: 0;
	width: 100%;
	-webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
	-o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
	transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.c-preloader .loader.dark_bg .loader-section .bg {
	background: #111339;
}

.c-preloader.loaded .animation-preloader {
	/*opacity: 0;*/
	-webkit-transition: 0.3s ease-out;
	-o-transition: 0.3s ease-out;
	transition: 0.3s ease-out;
}

.c-preloader.loaded .loader-section .bg {
	width: 0;
	-webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
	-o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
	transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

@-webkit-keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@-webkit-keyframes letters-static {
	0%,
	75%,
	100% {
		opacity: 0;
		-webkit-transform: rotateY(-90deg);
		transform: rotateY(-90deg);
	}
	25%,
	50% {
		opacity: 1;
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

@keyframes letters-static {
	0%,
	75%,
	100% {
		opacity: 0;
		-webkit-transform: rotateY(-90deg);
		transform: rotateY(-90deg);
	}
	25%,
	50% {
		opacity: 1;
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

@media screen and (max-width: 767px) {
	.c-preloader .animation-preloader .spinner {
		height: 8em;
		width: 8em;
	}
	.c-preloader .animation-preloader .txt-loading {
		font: bold 3.5em "Poppins", sans-serif;
	}
}

@media screen and (max-width: 500px) {
	.c-preloader .animation-preloader .spinner {
		height: 7em;
		width: 7em;
	}
	.c-preloader .animation-preloader .txt-loading {
		font: bold 2em "Poppins", sans-serif;
	}
}